import * as React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContentContainer from "../components/ContentContainer"
import ContentParagraph from "../components/ContentParagraph"
import Content from "../components/Content"
import ContentHeroTitle from "../components/ContentHeroTitle"
import { graphql, useStaticQuery } from "gatsby"


const TradingPage = () => {
  const { heroBgImage } = useStaticQuery(
    graphql`
      query {
        heroBgImage: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

  return (
    <Layout noPadding={true} headerFixed={false}>
      <React.Fragment>
        <SEO title="Trading | Long Rock Capital"/>
        <ContentHeroTitle headerImage={heroBgImage.childImageSharp.fluid} title={"Quantitative Trading"}/>
        <ContentContainer>
          <Content>
            <ContentParagraph>
              Long Rock Capital started in 2015 researching, building, and delivering quantitative trading systems for futures markets.
            </ContentParagraph>
            <ContentParagraph>
              In 2017, Long Rock Capital developed a low-latency trading system for cryptocurrencies.
            </ContentParagraph>
          </Content>
        </ContentContainer>
      </React.Fragment>
    </Layout>
  )
}

export default TradingPage